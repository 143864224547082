<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
      <GlobalSnack />
    </v-main>
  </v-app>
</template>

<script>
import GlobalSnack from "@/components/common/global-snack.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { GlobalSnack },
  data: () => ({}),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
      listing: (state) => state.listingsModule.currentListing,
    }),
  },
  watch: {
    listing(val) {
      this.setMetaTags(val);
    },
    settings: {
      handler() {
        this.setMetaTags({
          nickname: this.settings.sections.header.title,
          title: this.settings.sections.header.sub_title,
          picture: this.settings.bg_image,
        });
        const tag = this.settings.analytics.google;
        if (!tag) return;

        const tagManagerScript = document.createElement("script");
        const url = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
        tagManagerScript.setAttribute("src", url);
        document.head.appendChild(tagManagerScript);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", tag);

        const script = document.createElement("script");
        script.innerHTML = this.settings.injected_scripts;
        document.head.appendChild(script);
      },
    },
  },
  methods: {
    setMetaTags({ nickname, title, picture }) {
      const image = picture.replace(
        "upload/",
        "upload/w_600,h_336,c_fill,q_auto,f_auto/"
      );
      this.updateMetaTag("property", "og:title", nickname);
      this.updateMetaTag("property", "og:description", title);
      this.updateMetaTag("property", "og:image", image);
      this.updateMetaTag("property", "og:image:alt", "property image");
      this.updateMetaTag("property", "og:url", window.location.href);
      this.updateMetaTag("property", "og:type", "website");
    },
    updateMetaTag(attrName, attrValue, content) {
      let element = document.querySelector(`meta[${attrName}="${attrValue}"]`);
      if (!element) {
        element = document.createElement("meta");
        element.setAttribute(attrName, attrValue);
        document.head.appendChild(element);
      }
      element.setAttribute("content", content);
    },
  },
};
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@each $position in relative, absolute, fixed
  .p-#{$position}
    position: $position

.font-weight-semibold
  font-weight: 600 !important

.theme--light.v-divider
  border-color: map-get($grey, "lighten-3") !important
</style>
