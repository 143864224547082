<template>
  <v-card @click="listingClick" class="listings-item mb-3" elevation="0">
    <div class="p-relative">
      <v-toolbar
        class="pt-3"
        color="transparent"
        width="100%"
        absolute
        dense
        flat
      >
        <v-spacer />
      </v-toolbar>
    </div>
    <v-row :class="!horizontal ? 'flex-column' : ''">
      <v-col cols="12" :lg="carouselLg">
        <site-carousel
          :items="thumbnails"
          :listing-id="item.id"
          :slide.sync="slideIndex"
          easy
          item-height="220px"
        />
      </v-col>
      <v-col :class="{ 'pt-0': !horizontal }">
        <div class="d-flex flex-column fill-height">
          <v-card-title
            style="font-size: 1.2rem"
            :class="[
              { 'justify-space-between align-center': !horizontal },
              { 'flex-column-reverse align-start': horizontal },
              'mb-2 pa-0',
            ]"
          >
            <div class="word-break">
              {{ item.title }}
              <div class="d-flex mt-2" v-if="horizontal">
                <amenity-item
                  v-for="item in listingInfo"
                  :item="item"
                  :key="item.type"
                />
              </div>
            </div>
            <div
              :class="[
                'text-caption text--darken-2 w-full d-inline-flex align-center mt-1',
                { 'justify-space-between': !horizontal },
              ]"
              :style="'color: ' + primaryColor"
            >
              <div v-show="location">
                {{ location }}
              </div>
              <div class="d-flex" v-if="!horizontal">
                <amenity-item
                  v-for="item in listingInfo"
                  :item="item"
                  :key="item.type"
                />
              </div>
            </div>
          </v-card-title>
          <div class="mt-auto">
            <div
              v-if="item.extra_info.current_price"
              class="d-flex align-center"
            >
              <span
                class="font-weight-semibold"
                v-text="
                  toMoney(
                    item.extra_info.current_price.our_price,
                    0,
                    currencySign
                  )
                "
              />
              <span
                class="ml-1"
                v-if="
                  item.extra_info.current_price.total_price !==
                  item.extra_info.current_price.our_price
                "
                >+ Fees</span
              >
              <v-icon class="mx-3" size="3">fas fa-circle</v-icon>
              <span
                class="nights-amount text-caption text--secondary font-weight-medium"
                >{{ days_count }} nights</span
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { IMAGE_PLACEHOLDER } from "@/consts/CMS";
import { AMENITY_ICONS } from "@/consts/AMENITIES";
import { mapActions } from "vuex";
import MediaMixin from "@/mixins/media-mixin";
import FormattersMixin from "@/mixins/formatters-mixin";
import SiteCarousel from "@/components/common/carousel/site-carousel.vue";
// import ListingAmenities from "@/components/listing/listing-amenities";
import AmenityItem from "@/components/listing/amenity-item";
import stylesMixin from "@/mixins/styles-mixin";

export default {
  name: "site-listings-item",
  mixins: [MediaMixin, FormattersMixin, stylesMixin],
  components: { SiteCarousel, AmenityItem },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    showCalculations: {
      type: Boolean,
      default: false,
    },
    carouselLg: {
      type: Number,
      default: 12,
    },
  },
  data: () => ({ IMAGE_PLACEHOLDER, AMENITY_ICONS, slideIndex: 0 }),
  methods: {
    ...mapActions("listingsModule", ["updateListing"]),
    toggleFavorite(data) {
      const req = Object.assign({}, data);
      req.item.isFavorited = !req.item.isFavorited;
      this.updateListing(req);
    },
    listingClick() {
      this.$router.push({
        name: "listing",
        params: { id: this.item.id },
        query: this.$route.query,
      });
    },
  },

  computed: {
    thumbnails() {
      return this.item.pictures.length
        ? this.item.pictures?.map((p) =>
            (p.thumbnail || p.large || p.regular || p.original)
              .replace("upload/", "upload/c_fit,h_400/")
              .replace("h_200", "h_400")
          )
        : [this.item.picture];
    },
    listingInfo() {
      const beds = { type: "bedrooms", count: this.item.beds };
      const bathrooms = { type: "bathrooms", count: this.item.baths };
      const guests = { type: "guests", count: this.item.accommodates };
      return [guests, beds, bathrooms];
    },
    days_count() {
      const { check_in, check_out } = this.$route.query;
      if (!(check_in && check_out)) return null;
      return this.$moment(check_out).diff(this.$moment(check_in), "days");
    },
    location() {
      const { city_name, state_name } = this.item;
      if (!city_name && !state_name) return null;
      return `${city_name} ${state_name ? `, ${state_name}` : ""}`;
    },
  },
};
</script>

<style scoped>
.word-break {
  word-break: break-word;
}
</style>
