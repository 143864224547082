export const AMENITY_ICONS = {
  // Basic Property Info
  bedrooms: "mdi-bed",
  bathrooms: "mdi-shower",
  guests: "mdi-account-multiple",

  // Views
  "Lake View": "mdi-waves",
  "Pool view": "mdi-pool",
  "Beach View": "mdi-beach",
  "River View": "mdi-waves",
  "Mountain View": "mdi-mountain",
  "Garden View": "mdi-flower",
  "Bay View": "mdi-waves",
  "Canal view": "mdi-waves",
  "Courtyard view": "mdi-home",
  "Water View": "mdi-waves",
  "Country or rural view": "mdi-tree",
  Seaview: "mdi-beach",
  "Sea view": "mdi-beach",
  Town: "mdi-city",
  Downtown: "mdi-city",
  Village: "mdi-home-group",

  // Basic Amenities
  bathtub: "mdi-bathtub",
  "air conditioning": "mdi-air-conditioner",
  "Air conditioning": "mdi-air-conditioner",
  "Wireless Internet": "mdi-wifi",
  TV: "mdi-television",
  "Smart TV": "mdi-television-smart",
  "Big Screen TV": "mdi-television-classic",
  "LCD flat screen TV": "mdi-television-classic",
  "Cable TV": "mdi-television-box",

  // Kitchen & Dining
  microwave: "mdi-microwave",
  Microwave: "mdi-microwave",
  fridge: "mdi-fridge",
  "Mini fridge": "mdi-fridge",
  "fridge / freezer": "mdi-fridge",
  Refrigerator: "mdi-fridge",
  freezer: "mdi-fridge",
  Freezer: "mdi-fridge",
  Kitchen: "mdi-stove",
  "full kitchen": "mdi-stove",
  kitchenette: "mdi-countertop",
  "coffee maker": "mdi-coffee-maker",
  "Coffee maker": "mdi-coffee-maker",
  Coffee: "mdi-coffee-maker",
  "coffee tea maker": "mdi-coffee-maker",
  stove: "mdi-stove",
  Stove: "mdi-stove",
  oven: "mdi-stove",
  Oven: "mdi-stove",
  Dishwasher: "mdi-dishwasher",
  "dining area": "mdi-table-furniture",
  "dining room": "mdi-table-furniture",
  "dining table": "mdi-table-furniture",
  "Dining table": "mdi-table-furniture",
  "kitchen dining area": "mdi-table-furniture",
  "wine glasses": "mdi-glass-wine",
  "Wine glasses": "mdi-glass-wine",
  plates: "mdi-plate-variant",
  blender: "mdi-blender",
  Blender: "mdi-blender",
  toaster: "mdi-toaster",
  "Ice Maker": "mdi-ice-pop",

  // Laundry
  "Washing Machine": "mdi-washing-machine",
  Washer: "mdi-washing-machine",
  washer: "mdi-washing-machine",
  Dryer: "mdi-tumble-dryer",
  "Washer dryer": "mdi-washing-machine",
  "washing machine with drier": "mdi-washing-machine",
  "Dryer in common space": "mdi-tumble-dryer",
  Laundry: "mdi-washing-machine",
  "Laundromat nearby": "mdi-washing-machine",

  // Bathroom
  Toiletries: "mdi-shampoo",
  Bathroom: "mdi-toilet-outline",
  shower: "mdi-shower-head",
  "bath with shower": "mdi-shower-head",
  "separate tub and shower": "mdi-shower-head",
  toilet: "mdi-toilet",
  "Toilet paper": "mdi-toilet-paper",
  bidet: "mdi-water-pump",
  "additional toilet": "mdi-toilet",
  "Body soap": "mdi-pump-soap",
  "Shower gel": "mdi-pump-soap",
  "shared bathroom": "mdi-account-group",
  "private bathroom": "mdi-human-male-female",

  // Furniture & Storage
  desk: "mdi-desk",
  Desk: "mdi-desk",
  sofa: "mdi-sofa",
  "closets in room": "mdi-closet",
  "Walk in closet": "mdi-closet",
  "Clothing storage": "mdi-closet",
  hangers: "mdi-hanger",
  hanger: "mdi-hanger",
  "clothes rack": "mdi-hanger",
  mirror: "mdi-mirror",
  "storage space": "mdi-archive",

  // Bedding & Comfort
  "Bed Linen": "mdi-bed",
  "bed linens": "mdi-bed",
  "Bed Linen & Towels": "mdi-bed",
  "extra pillows and blankets": "mdi-pillow",
  Towels: "mdi-towel",
  "beach towels": "mdi-beach",
  "room darkening shades": "mdi-blinds",
  "Room-darkening shades": "mdi-blinds",

  // Outdoor
  garden: "mdi-flower",
  "Garden or backyard": "mdi-flower",
  "BBQ grill": "mdi-grill",
  "Barbeque utensils": "mdi-grill",
  Balcony: "mdi-balcony",
  porch: "mdi-door",
  Terrace: "mdi-deck",
  Patio: "mdi-deck",
  "Patio or balcony": "mdi-deck",
  firepit: "mdi-fire",
  "outdoor fireplace": "mdi-fire",
  "Outdoor seating (furniture)": "mdi-chair-rolling",

  // Pool & Recreation
  "swimming pool": "mdi-pool",
  "Swimming pool": "mdi-pool",
  Pool: "mdi-pool",
  "Private pool": "mdi-pool",
  "Indoor pool": "mdi-pool",
  "Outdoor Pool": "mdi-pool",
  "Heated Pool": "mdi-pool",
  "Communal pool": "mdi-pool",
  "Shared Swimming Pool": "mdi-pool",
  "spa pool": "mdi-hot-tub",
  "pool in ground": "mdi-pool",
  "pool sun loungers": "mdi-beach",
  "pool umbrellas": "mdi-umbrella-beach",
  jacuzzi: "mdi-hot-tub",
  "Hot tub": "mdi-hot-tub",
  "Game room": "mdi-gamepad-variant",
  "Tennis Court": "mdi-tennis",
  Tennis: "mdi-tennis",
  "Ping-pong table": "mdi-table-tennis",
  "Table Football": "mdi-football",
  "Billiards / Pool Tables": "mdi-billiards",

  // Safety & Security
  "Smoke Detectors": "mdi-smoke-detector",
  "Smoke detector": "mdi-smoke-detector",
  "Fire Extinguisher": "mdi-fire-extinguisher",
  "Fire extinguisher": "mdi-fire-extinguisher",
  "Carbon Monoxide Detector": "mdi-smoke-detector-variant",
  "Carbon monoxide detector": "mdi-smoke-detector-variant",
  "first aid kit": "mdi-medical-bag",
  "First aid kit": "mdi-medical-bag",
  Safe: "mdi-safe",
  "Safety card": "mdi-card-text",
  Lockbox: "mdi-lock-box",
  "Electronic Door Locks": "mdi-lock-smart",
  "Keypad check-in": "mdi-keyboard",
  "Lock on bedroom door": "mdi-lock",
  "Smart lock": "mdi-lock-smart",
  "Self check-in": "mdi-login",
  "Host greets you": "mdi-account-check",
  "Surveillance cameras on property": "mdi-cctv",

  // Family & Children
  "Baby high chair": "mdi-baby-carriage",
  "High chair": "mdi-chair-rolling",
  "High Chairs": "mdi-chair-rolling",
  "Suitable for children": "mdi-human-male-child",
  "Children not allowed": "mdi-human-male-child-off",
  "Infants not allowed": "mdi-baby-remove",
  "Suitable for infants": "mdi-baby",
  "Suitable for infants (under 2 years)": "mdi-baby",
  "Suitable for children (2-12 years)": "mdi-human-male-child",
  "Family/kid friendly": "mdi-human-male-female-child",
  "Family friendly": "mdi-human-male-female-child",
  "Family/kids friendly": "mdi-human-male-female-child",
  "Children's books and toys": "mdi-toy-brick",
  "Children's dinnerware": "mdi-silverware-variant",
  "childrens books and toys": "mdi-toy-brick",
  "childrens dinnerware": "mdi-silverware-variant",
  Crib: "mdi-baby-carriage",
  "Pack n Play/travel crib": "mdi-baby-carriage",
  "Baby bath": "mdi-baby",
  "baby safety gates": "mdi-gate",
  "Changing table": "mdi-baby-changing-table",
  "changing table": "mdi-baby-changing-table",

  // Accessibility
  Elevator: "mdi-elevator",
  "Wheelchair accessible": "mdi-wheelchair-accessibility",
  "Wide hallways": "mdi-door-sliding",
  "Accessible-height bed": "mdi-bed",
  "Accessible-height toilet": "mdi-toilet",
  "Wide clearance to shower and toilet": "mdi-door-open",
  "Wide clearance to bed": "mdi-door-open",
  "Flat smooth pathway to front door": "mdi-road-variant",

  // Internet & Entertainment
  "internet connection": "mdi-wifi",
  Internet: "mdi-wifi",
  "FREE internet access": "mdi-wifi",
  "Free cable internet": "mdi-ethernet",
  "High speed Internet access": "mdi-wifi",
  "Paid wireless internet": "mdi-wifi-lock",
  "Free high speed wireless internet": "mdi-wifi-strength-4",
  "Free Wireless Internet": "mdi-wifi-strength-4",
  "Game console": "mdi-gamepad-variant",
  "Video game system": "mdi-gamepad-variant",
  "Board games": "mdi-puzzle",
  "board games puzzles": "mdi-puzzle",
  "board games/puzzles": "mdi-puzzle",
  "Stereo system": "mdi-speaker",

  // Climate Control
  Fan: "mdi-fan",
  "Ceiling fan": "mdi-fan",
  "Ceiling Fan": "mdi-fan",
  heating: "mdi-radiator",
  Heating: "mdi-radiator",
  "Central Heating": "mdi-radiator",
  "room climate control": "mdi-thermometer",
  "Temperature Control": "mdi-thermometer",

  // Parking
  Parking: "mdi-parking",
  "Free Parking": "mdi-parking",
  "Private parking": "mdi-parking",
  "Outdoor Parking": "mdi-parking",
  "Free parking on premises": "mdi-parking",
  "Free parking on the street": "mdi-parking",
  "Free street parking": "mdi-parking",
  "Paid parking": "mdi-parking",
  "Paid parking on premises": "mdi-parking",
  "Underground parking": "mdi-parking-box",
  garage: "mdi-garage",

  // Cleaning & Hygiene
  "cleaning products": "mdi-spray-bottle",
  "Cleaning products": "mdi-spray-bottle",
  "Cleaning Disinfection": "mdi-spray",
  "Enhanced cleaning practices": "mdi-broom",
  "Disinfectants used for cleaning": "mdi-spray",
  "High touch surfaces disinfected": "mdi-hand-sanitizer",
  "Contactless check-in and checkout is available.": "mdi-contactless-payment",

  // Essentials
  Iron: "mdi-iron",
  "Iron & Ironing Board": "mdi-iron-board",
  "Ironing Board": "mdi-iron-board",
  "Hair Dryer": "mdi-hair-dryer",
  Essentials: "mdi-checkbox-marked-circle-outline",
  "essentials (towels, pillows, bed linen, soap, toilet paper)":
    "mdi-checkbox-marked-circle-outline",
  Umbrella: "mdi-umbrella",
  "mosquito net": "mdi-mosquito-off",

  // Location Features
  Waterfront: "mdi-waves",
  Beachfront: "mdi-beach",
  "Beach Front": "mdi-beach",
  "Ocean Front": "mdi-beach",
  Beach: "mdi-beach",
  "Beach access": "mdi-beach",
  "Near Ocean": "mdi-beach",
  "Near ocean": "mdi-beach",
  "Lake Front": "mdi-waves",
  "Lake access": "mdi-waves",
  River: "mdi-waves",

  // Rules & Policies
  "Smoking not allowed": "mdi-smoking-off",
  "No parties": "mdi-party-popper-off",
  "No Smoking Rooms/Facilities": "mdi-smoking-off",
  "Pets allowed": "mdi-paw",
  "No Pets Allowed": "mdi-paw-off",
  "Suitable for events": "mdi-party-popper",
  "Long term stays allowed": "mdi-calendar-month",

  // Workspace
  "Laptop workspace": "mdi-laptop",
  "Laptop friendly workspace": "mdi-laptop",
  "Private living room": "mdi-sofa",
  "Living room": "mdi-sofa",
  "seating area": "mdi-sofa",

  // Sports & Fitness
  "Play Ground": "mdi-swing",
  "Fitness Room": "mdi-dumbbell",
  Gym: "mdi-dumbbell",

  // Default
  default: "mdi-checkbox-marked-circle-outline",
};

export const AMENITIES = (): string[] => {
  return Object.keys(AMENITY_ICONS);
};

export const AMENITIES_DEFAULTS = {
  Seaview: "Ocean View",
  "Marina View": "Harbor view",
  "partial ocean view": "partial sea view",
  "partial lake view": "Lake View",
  "internet connection": "Wireless Internet",
  "Iron & Ironing Board": "Iron",
  kitchenette: "Kitchen",
  "coffee tea maker": "coffee maker",
  "Video game system w/games": "Video game system",
  "Free high speed wireless internet": "Free Wireless Internet",
  "dining area": "dining table",
  "seating area": "Living room",
  "laptop safe box": "Safe",
  "Trouser Press": "Iron & Ironing Board",
  "Baby high chair": "High Chairs",
  "clothes rack": "hangers",
  "full kitchen": "Kitchen",
  "Laptop workspace": "desk",
  "First aid kit available": "first aid kit",
  "Temperature Control": "air conditioning",
  "Screens or physical barriers placed between staff and guests in appropriate areas":
    "Staff follow all safety protocols from local authorities",
  "Parties allowed": "No parties",
  "No Smoking Rooms/Facilities": "Smoking not allowed",
  "Paid parking on the street": "Parking",
  "Cookware & Kitchen Utensils": "kitchenware",
  "Safe Deposit": "Safe",
  "Family/kids friendly": "Family friendly",
  "electric car charging station": "electric vehicle charger",
  "Tumble dryer": "Dryer",
  "A Gym is in the building for guests to use": "Residence gym",
  "computer with free ASDL internet access": "Internet (computer supplied)",
  jacuzzi: "Hot Tub",
  "Kitchen in the living / dining room": "kitchenette",
  "Modern Kitchen": "Kitchen",
  "cooking hob": "stove",
  "Desk with lamp": "desk",
  "Shared Swimming Pool": "Communal pool",
  "separate entry": "private entrance",
  "TV (local channels only)": "TV",
  "Wood burning fireplace": "Fireplace",
  Smoking: "Smoking allowed",
  "Toilet paper": "Toiletries",
  Soap: "Toiletries",
  "Baby cot": "Cribs Available",
  "Crockery & Cutlery": "kitchenware",
  Patio: "Terrace",
  "room darkening shades": "blinds",
  "cooking basics": "kitchenware",
  "Complimentary Soap/Shampoo/Conditioner": "Toiletries",
  "bed linens": "Bed Linen",
  "board games puzzles": "board games/puzzles",
  "Bathroom shared with other guests": "shared bathroom",
  Terrace: "Balcony",
  "Gas stove": "stove",
  "Outdoor Parking": "Parking",
  "En suite shower": "private bathroom",
  "toilet/bidet": "bidet",
  "Digital Cable TV": "Cable TV",
  "Common Kitchen": "Shared Kitchen",
  "Phone Service": "Telephone",
  "FREE international phoneline": "Free long distance phone calls",
  "Telephone (incoming and outgoing calls)": "Telephone",
  "Small Balcony": "Balcony",
  "built-in wardrobes": "wardrobe",
  wardrobes: "wardrobe",
  plates: "Cookware & Kitchen Utensils",
  "outdoor fireplace": "firepit",
  "Fitness Room": "Gym",
  "Outdoor Tennis": "Tennis Court",
  "dining room": "dining area",
  "Ask for pets": "Pets accepted under request",
  "Espresso-Machine": "coffee maker",
  "gas/electric hob": "stove",
  "room climate control": "air conditioning",
  "casino nearby": "Casino",
};
