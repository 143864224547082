<template>
  <div class="site-header">
    <v-app-bar
      app
      :flat="flat"
      :dark="dark"
      :color="dark ? 'transparent' : 'white'"
      class="site-header-bar"
      :height="siteHeaderHeight"
      :inverted-scroll="inverted"
    >
      <div class="d-flex" v-if="filters">
        <v-btn class="mr-3 pa-0" small text @click="$router.push('/')">
          <v-icon class="mr-3" small> fa fa-chevron-left </v-icon>
          <span v-if="!isMobile" class="font-weight-medium text--secondary"
            >Back</span
          >
        </v-btn>
      </div>
      <router-link class="header-router-link" v-else to="/">
        <img
          v-if="!dark"
          :src="settings.logo"
          :width="logoWidth"
          class="mr-2 logo-img pa-2"
        />
      </router-link>
      <v-spacer />
      <listing-filters-bar v-if="filters" />
      <!-- <v-text-field
        v-else
        color="info"
        class="d-none d-sm-block"
        label="Search Location..."
        hide-details
        solo-inverted
        flat
        prepend-inner-icon="$search"
      /> -->
      <v-spacer v-if="filters" />
      <v-sheet v-if="!isMobile" color="transparent" class="pa-0">
        <!-- <v-btn text>
          <v-icon>far fa-heart</v-icon>
          <span v-show="!isMobileOrIPad" class="text-capitalize ml-2"
            >Wishlist</span
          >
        </v-btn> -->
        <v-btn @click="listingsSearch" text>
          <v-icon small :color="dark ? 'white' : primaryColor"
            >fa fa-search</v-icon
          >
          <span class="text-capitalize ml-2">Search</span>
        </v-btn>
        <v-btn :href="portalUrl" target="_blank" text>
          <v-icon small :color="dark ? 'white' : primaryColor"
            >far fa-user</v-icon
          >
          <span class="text-capitalize ml-2">Portal</span>
        </v-btn>
        <v-btn href="/" text>
          <v-icon small :color="dark ? 'white' : primaryColor"
            >fa fa-home</v-icon
          >
          <span class="text-capitalize ml-2">Home</span>
        </v-btn>
      </v-sheet>
      <!-- <listing-filters-dialog v-if="isMobile && filters" /> -->
      <!-- <template #extension v-if="filters">
        <v-container class="px-0" fluid>
          <v-row no-gutters>
            <v-col cols="12" sm="10" lg="11">
              <listing-tags />
            </v-col>
            <v-col cols="12" sm="2" lg="1" class="d-none d-sm-block text-right">
              <listing-filters-dialog v-if="!isMobile" />
            </v-col>
          </v-row>
        </v-container>
      </template> -->
      <!-- <v-icon>far fa-user</v-icon> -->
    </v-app-bar>
  </div>
</template>

<script>
import { LOGO, LOGO_WHITE } from "@/consts/CMS";
import DeviceMixin from "@/mixins/device-mixin";
import MediaMixin from "@/mixins/media-mixin";
import StylesMixin from "@/mixins/styles-mixin";
// import ListingFiltersDialog from "@/components/listing/listing-filters-dialog";
import ListingFiltersBar from "@/components/listing/listing-filters-bar.vue";
import { mapState } from "vuex";

export default {
  name: "site-header",
  components: { ListingFiltersBar },
  mixins: [DeviceMixin, MediaMixin, StylesMixin],
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    portalUrl() {
      return process.env.VUE_APP_PORTAL_URL || "https://guest.boomnow.com/";
    },
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    listingsSearch() {
      this.$router.push({
        name: "listings",
        query: this.$route.query,
      });
    },
  },
  data: () => ({ LOGO, LOGO_WHITE }),
};
</script>

<style lang="scss">
.site-header-bar {
  &.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 1px 1px 0px map-get($grey, "lighten-3");
  }
}
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
.logo-img {
  object-fit: contain;
  height: 100%;
}
.header-router-link {
  height: 100%;
}
</style>
