<template>
  <v-sheet @scroll="!isMobile && handleDeskTopScroll($event)" class="listings">
    <h2
      v-if="title"
      class="text-center text-h5 font-weight-medium my-7 mb-md-16"
      v-text="title"
    />
    <v-row class="justify-center">
      <v-col
        v-for="(item, index) in listings"
        :key="item.id"
        :cols="cols"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
      >
        <site-listings-item
          :item="item"
          :index="index"
          :horizontal="horizontal"
          :carousel-lg="carouselLg"
          :show-calculations="showCalculations"
        />
      </v-col>
      <v-col
        v-if="listings.length === 0 && !loading"
        class="d-flex justify-center secondary--text text--lighten-3 text-h6 mt-10"
      >
        No results found
      </v-col>
      <v-col
        v-if="loading"
        :cols="cols"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
        class="text--secondary text-body-2 text-center"
        >loading...
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SiteListingsItem from "@/components/home/site-listings-item.vue";
import DeviceMixin from "@/mixins/device-mixin";

export default {
  name: "site-listings",
  components: { SiteListingsItem },
  mixins: [DeviceMixin],

  props: {
    title: String,
    horizontal: {
      type: Boolean,
      default: false,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: [Number, String],
      default: 12,
    },
    sm: {
      type: [Number, String],
      default: 6,
    },
    md: {
      type: [Number, String],
      default: 4,
    },
    lg: {
      type: [Number, String],
      default: null,
    },
    xl: {
      type: Boolean,
      default: null,
    },
    carouselLg: {
      type: Number,
      default: 12,
    },
    showCalculations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { isLoading: false, page: 1 };
  },
  computed: {
    ...mapState({
      listings: (state) => state.listingsModule.listings,
      pagination: (state) => state.listingsModule.pagination,
      loading: (state) => state.listingsModule.loading,
    }),
  },
  beforeMount() {
    this.setListings([]);
  },
  mounted() {
    this.getListings(this.featured ? { featured: true } : this.$route.query);
    if (!this.isMobile) return;

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("touchmove", this.handleScroll);
  },
  beforeDestroy() {
    if (!this.isMobile) return;

    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("touchmove", this.handleScroll);
  },
  methods: {
    ...mapActions("listingsModule", ["getListings"]),
    ...mapMutations("listingsModule", ["setListings"]),
    async handleDeskTopScroll({
      target: { scrollHeight, clientHeight, scrollTop },
    }) {
      const { page, per_page, count } = this.pagination;
      const hasNext = page * per_page < count;

      const scrollPosition = scrollTop + clientHeight;
      const threshold = scrollHeight * 0.8;

      if (hasNext && !this.isLoading && scrollPosition >= threshold) {
        this.getNextPage();
      }
    },
    async handleScroll() {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;

      const scrollPosition = scrollTop + clientHeight;
      const threshold = scrollHeight * 0.8;

      const { page, per_page, count } = this.pagination;
      const hasNext = page * per_page < count;

      if (hasNext && !this.isLoading && scrollPosition >= threshold) {
        this.getNextPage();
      }
    },
    getNextPage() {
      this.isLoading = true;
      this.page += 1;
      this.getListings({
        ...this.$route.query,
        page: this.page,
        append: true,
      });
    },
  },
  watch: {
    "$route.query"() {
      this.getListings(this.featured ? { featured: true } : this.$route.query);
    },
    pagination(val, oldVal) {
      if (val.page > oldVal.page) this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
